.button {
  width: 100%;
  padding: 0 15px 0 8px;

  border: none;
  border-radius: 6px;
  border: 1px solid var(--blue-primary);

  color: #fff;
  background: var(--blue-primary);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  letter-spacing: 0.5px;
  font-weight: 500;
  outline: none;
}

.btn_secondary,
.moreBtn {
  background-color: #fff;
  color: var(--blue-primary);
  border: 1px solid var(--blue-primary);
}

.button:disabled {
  color: var(--neutral-dark-4);
  border-color: var(--neutral-dark-4);
  box-shadow: none;
  cursor: not-allowed;
}

.icon {
  margin-right: 6px;
  align-self: center;
}

.text {
  padding-top: 2px;
}

.spinner {
  margin-left: 7.5px;
}

.moreBtn {
  padding: 0;
  padding-left: 10px;
  line-height: 32px;
  width: fit-content;
}

.moreBtn .icon {
  margin-left: 5px;
}

.moreBtn .text {
  padding: 0;
}

@media screen and (min-width: 768px) {
  .button {
    min-width: fit-content;
    height: 40px;
  }

  .button:hover {
    opacity: 0.9;
    box-shadow: 2.5px 2.5px 2.5px var(--neutral-dark-4);
    outline: none;
  }
}
