.section {
  margin-bottom: 35px;
}

.body {
  font-size: 14px;
}

section p {
  font-size: 14px;
}
