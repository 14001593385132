:root {
  --blue-dark: #003b71;
  --blue-light: #a6c4e0;
  --blue-primary: #2b99ff;
  --blue-transparent: #65b5ff41;
  --blue-transparent-15: #65b5ff15;
  --blue-transparent-75: #65b5ff75;

  --orange-primary: #ffaa2b;
  --orange-transparent-15: #ffaa2b15;
  --orange-transparent-25: #ffaa2b25;
  --orange-transparent-50: #ffaa2b50;

  --neutral-dark-0: #000000;
  --neutral-dark-1: #252525;
  --neutral-dark-2: #3f3f3f;
  --neutral-dark-3: #a0a0a0;
  --neutral-dark-4: #c4c4c4;
  --neutral-dark-5: #dddddd;
  --neutral-dark-6: #f5f5f5;
  --neutral-dark-7: #fbfbfb;
  --neutral-light: #ffffff;

  --purple-primary: #9b4473;

  --green-dark: #408a6f;
  --green-light: rgba(173, 209, 241, 0.26);

  --danger: #e36b6b;
  --success: #71a05a;
}
