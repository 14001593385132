.wrapper {
  padding: 100px 0;
}

.formWrapper {
  background: var(--green-light);
  margin: 0 auto;
  border-radius: 20px !important;
  box-shadow: 4px 4px 15px 8px rgba(245, 245, 245, 1);
  padding: 20px;
  display: grid;
  row-gap: 40px;
}

.formWrapper h2 {
  font-family: "DMSerifDisplay";
  font-size: 30px;
  font-weight: 400;
  line-height: 35px;
  margin: 0;
}

.formWrapper p {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
}

.formCol label {
  font-size: 13px;
  font-family: "Poppins";
  font-weight: 500;
  line-height: 19.5px;
}

.telephone {
  width: 100%;
  max-width: 200px;
  max-height: 308px;
  order: 0;
  margin: 0 auto;
}

.checkBox {
  font-size: 14px;
}

.customCheckbox {
  display: inline-block;
  border-radius: 3px;
  margin-right: 5px;
  width: 16px;
  height: 17px;
  border: 1px solid var(--blue-primary);
  background-color: var(--blue-primary);
  cursor: pointer;
}

.customCheckbox.checked {
  background-color: var(--blue-primary);
}

.checkmark {
  display: block;
  color: var(--neutral-dark-0);
  text-align: center;
  font-weight: bolder;
  font-size: 14px;
}

.send {
  font-family: "Poppins";
  font-size: 14px;
}

.sitename {
  color: var(--blue-primary);
  font-family: "DMSerifDisplay";
  font-size: 20px;
}

.news {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
}

.sitename>.stub {
  color: var(--orange-primary);
  letter-spacing: 1px;
}

.alert {
  display: flex;
  justify-content: space-between;
  border-radius: 3px;
  margin-bottom: 10px;
  padding: 10px;
}

.closeIcon {
  font-size: 24px;
  font-weight: bold;
  line-height: 24px;
  cursor: pointer;
}

.formGrid2Cols {
  display: grid;
  row-gap: 20px;
}

.col2 {
  grid-column: 1/3;
}

.input,
.textarea {
  background: none;
  border: none;
  border-bottom: 2px solid var(--neutral-light);
  width: 100%;
  padding: 0;
  font-family: "Poppins";
  font-weight: 400;
  color: var(--neutral-dark-01);
  font-size: 14px;
  line-height: 21px;
  outline: none;
  resize: none;
  margin-top: 10px;
}

.button {
  border: 1px solid var(--green-dark);
  background-color: var(--green-dark);
  color: var(--neutral-light);
  border-radius: 8px;
  cursor: pointer;
  font-family: "Poppins";
  font-weight: 600;
  font-size:16px;
  margin-top: 20px;
  width: 100%;
  max-width: 200px;
  max-height: 50px;
  text-align: center;
  padding: 10px 0;
}

.contactForm {
  display: grid;
  row-gap: 20px;
}

.spinner {
  padding-top: 10px;
  margin-right: 7px;
}

.formWrapper>div:last-child {
  display: grid;
  place-content: center;
  order: 1;
}

.formWrapper>div:first-child {
  order: 2;
}

.error {
  color: var(--danger);
  font-size: 12px;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .wrapper {
    max-width: 610px;
    margin: 0 auto;
  }
  .telephone {
      max-width: 280px;
    }
}

@media screen and (min-width: 768px) {
  .formWrapper {
    padding: 30px;
  }

  .formGrid2Cols {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
  }
  
}

@media screen and (min-width: 1024px) {
  .wrapper{
    height: 850px
  }
  .formWrapper {
    grid-template-columns: repeat(2, 1fr);
    padding: 30px 50px;
    column-gap: 50px;
    max-width: 969px;
    margin: 70px 18px;
  }

  .formWrapper>div:first-child {
    order: 1;
  }

  .formWrapper>div:last-child {
    order: 2;
  }

  .formWrapper p {
    font-size: 15px;
    line-height: 22px;
  }

  .formWrapper h2 {
    font-size: 32px;
    line-height: 45px;
  }

  .button {
    max-width: 380px;
    padding: 9px 0;
  }

  .checkBox {
    font-size: 16px;
    line-height: 24px;
  }

  .customCheckbox {
    margin-bottom: -2px;
    margin-right: 11px;
    width: 20px;
    height: 20px;
  }

  .checkmark {
    font-size: 15px;
  }

  .send {
    font-size: 18px;
    line-height: 32px;
  }

  .telephone {
    max-width: 350px;
  }
}
@media screen and (min-width:1280px) {
  .formWrapper{
    margin-left: 8.5em !important;
    margin-right: 8.5em !important;
  }
  .button {
    max-width: 415px;
  }
}
@media screen and (min-width: 1440px) {
  .formWrapper {
    padding: 20px;
    max-width: 1145px;
    max-height: 579px;
    margin-left: 11em !important;
    margin-right: 9.5em !important;

  }
  .telephone {
    max-width: 390px;
  }
}
@media screen and (min-width:1536px) {
  .formGrid2Cols {
    column-gap: 5em;
  }
  .formWrapper{
    column-gap: 60px;
    padding-right: 10px;
  }
}