.footer {
  background-color: var(--blue-dark);
  color: var(--neutral-light);
  padding: 30px 0;
  margin-top: 70px;
}

.detail {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 0px;
  margin-bottom: 40px;
  color: var(--neutral-light);
}

.info {
  padding: 10px;
}
.formInput {
  text-align: center;
}
.siteIcon {
  width: 30px;
  margin-right: 10px;
}

.siteInfo {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.contact {
  display: grid;
  row-gap: 10px;
}

.contact > div {
  display: flex;
  align-items: center;
}

.contact span {
  font-family: "Poppins";
  font-size: 14px;
  line-height: 20px;
}

.contact img {
  width: 20px;
  max-height: 20px;
  margin-right: 15px;
}

.siteInfo span {
  font-family: "DMSerifDisplay";
  color: var(--neutral-light) !important;
  font-weight: 400;
  font-size: 25px;
}
.mailText a {
  color: var(--neutral-light) !important;
}
.phoneText a {
  text-decoration: none;
  color: var(--neutral-light) !important;
}
.footerColumn {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  padding: 20px;
  gap: 20px;
  column-gap: 73px;
  margin-top: 20px;
}

.footerColumn > div {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.footerColumn h4 {
  color: var(--blue-primary);
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 5px;
}

.footerColumn a,
.footerCompany a {
  text-decoration: none;
  font-size: 13px;
  font-family: "Poppins";
  cursor: pointer;
  color: var(--neutral-light);
}
.footerCompany {
  display: flex;
}

.footerCompany .colHead {
  margin-bottom: 5px;
}

.footerCompany a {
  color: #fff;
}
.footerCompany h4 {
  margin: 0;
}
.copyright {
  font-size: 12px;
  font-family: "Poppins";
  font-weight: 400;
  color: var(--neutral-light);
  line-height: 18px;
  display: block;
}

.footnote {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.socialIcons {
  padding: 10px 0;
}

.socialIcons img {
  width: 20px;
  height: 20px;
  margin-right: 25px;
}

.socialIcons a:last-child img {
  margin-right: 0;
}

@media screen and (min-width: 768px) {
  .formInput {
    text-align: center;
    width: 80%;
  }
  .footerColumn {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 1024px) {
  .detail {
    flex-direction: row;
  }

  .contact {
    margin-top: 55px;
    margin-bottom: 0;
  }

  .siteInfo span {
    font-size: 34px;
  }
  .copyright {
    margin-right: 1.7em;
  }
  .siteIcon {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }

  .contact span {
    font-size: 16px;
    line-height: 24px;
  }

  .footerColumn {
    grid-template-columns: repeat(2, 1fr);
  }

  .footerColumn > div {
    margin-bottom: 50px;
  }

  .footerColumn h4 {
    margin-top: 0;
  }

  .footerCompany {
    flex-direction: column;
  }
  .footnote {
    flex-direction: row;
    justify-content: space-between;
  }
}

@media screen and (min-width: 1366px) {
  .footer {
    padding: 80px 50px 0;
    max-height: 750px;
  }
  .siteIcon {
    height: 45px;
    width: 45px;
  }

  .siteInfo span {
    font-size: 44px;
    line-height: 65.81px;
    margin-left: 9px;
  }
}
@media screen and (min-width: 1536px) {
  .copyright {
    line-height: 40px;
    margin-right: 5.3em;
  }
}
