.headerLayout {
  position: sticky;
  top: 0;

  display: flex;
  justify-content: space-between;
  gap: 10px;

  border-bottom: 1px solid var(--blue-transparent);
}

.brandingArea {
  width: 30%;
  background-color: var(--blue-dark);
  padding: 15px 25px;
}

.siteLink {
  align-items: center;
  display: inline-flex;
  gap: 10px;

  text-decoration: none;
}

.siteIcon {
  width: 24px;
  height: 24px;
}

.siteName {
  color: var(--neutral-light);
  font-family: "DMSerifDisplay";
  font-size: 24px;
  letter-spacing: 0.5px;
}

.stub {
  color: var(--orange-primary);
}

.actions {
  display: flex;
  gap: 10px;

  padding: 15px 25px;
}

.loginBtn {
  background-color: transparent;
  border-radius: 50px;
  padding: 5px 35px;

  color: var(--blue-primary);
  font-size: 14px;
  font-weight: bold;
}

.signUpBtn {
  border-radius: 50px;
  padding: 5px 35px;

  font-size: 14px;
}

@media screen and (min-width: 1280px) {
  .brandingArea {
    width: 22.5%;
  }
}

@media screen and (min-width: 1500px) {
  .brandingArea {
    width: 15%;
  }
}
