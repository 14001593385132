@font-face {
  font-family: "Solway";
  src: url("../font/Solway-Light.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Solway";
  src: url("../font/Solway-Regular.ttf") format("truetype");
  font-weight: 200 400;
  font-style: normal;
}

@font-face {
  font-family: "Solway";
  src: url("../font/Solway-Medium.ttf") format("truetype");
  font-weight: 500 600;
  font-style: normal;
}

@font-face {
  font-family: "Solway";
  src: url("../font/Solway-Bold.ttf") format("truetype");
  font-weight: 700 900;
  font-style: normal;
}

@font-face {
  font-family: "DMSerifDisplay";
  src: url("../font/dm-serif/DMSerifDisplay-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../font/poppins/Poppins-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../font/poppins/Poppins-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../font/poppins/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("../font/poppins/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "PlayfairDisplay";
  src: url("../font/playfair/PlayfairDisplay-Black.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "PlayfairDisplay";
  src: url("../font/playfair/PlayfairDisplay-Regular.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "PlayfairDisplay";
  src: url("../font/playfair/PlayfairDisplay-Medium.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "PlayfairDisplay";
  src: url("../font/playfair/PlayfairDisplay-SemiBold.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "PlayfairDisplay";
  src: url("../font/playfair/PlayfairDisplay-Bold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "PlayfairDisplay";
  src: url("../font/playfair/PlayfairDisplay-ExtraBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

:root {
  --font-family: "Poppins";
}
