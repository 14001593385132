.partnersSection {
  background-color: var(--neutral-dark-6);
  text-align: center;
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;

  padding: 20px 0px;
}

.title {
  font-family: "DMSerifDisplay";
  font-size: 16px;
  color: var(--neutral-dark-3);
  letter-spacing: 2px;
  text-transform: uppercase;
}

.partnersContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
}

.partnerLogo {
  max-height: 50px;
  object-fit: contain;
}

.brand {
  width: 125px;
  height: auto;
}

.dots {
  position: unset;
  display: none;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}

.dots li {
  display: none;
}

.overlay {
  height: 54px;
  margin-top: -54px;
  position: relative;

  background: linear-gradient(
    90deg,
    var(--neutral-dark-6) 0% 5%,
    rgba(255, 255, 255, 0.1) 12% 86%,
    var(--neutral-dark-6) 97% 100%
  );
}

@media screen and (min-width: 400px) {
  .brand {
    width: 115px;
  }
}

@media screen and (min-width: 768px) {
  .slider {
    padding: 25px 0;
  }

  .title {
    font-family: "DMSerifDisplay";
    font-size: 24px;
  }

  .brand {
    width: 125px;
  }

  .overlay {
    height: 104px;
    margin-top: -104px;
    position: relative;

    background: linear-gradient(
      90deg,
      var(--neutral-dark-6) 0% 5%,
      rgba(255, 255, 255, 0.1) 12% 86%,
      var(--neutral-dark-6) 97% 100%
    );
  }
}

@media screen and (min-width: 1536px) {
  .brand {
    width: 200px;
  }
}
