.rselect {
  width: 100%;
  border-radius: 5px;
  color: var(--neutral-dark-3);
}

.rselect div {
  width: auto;
}

.rselect div::-webkit-scrollbar {
  width: 4px;
  height: 8px;
  background-color: #fff;
}

/* Add a thumb */
.rselect div::-webkit-scrollbar-thumb {
  background: var(--blue-primary);
  border-radius: 20px;
}

.rselect:hover,
.rselect:focus-within,
.rselect:focus {
  border-color: var(--blue-primary);
}

.control {
  border: 1px solid var(--neutral-dark-4);
  padding: 1px;
  width: 100% !important;
}

.option:hover {
  color: var(--neutral-light) !important;
  background-color: var(--blue-primary);
}

.menulist {
  display: flex;
  align-items: center;
}

.menulist_header {
  padding: 8px 12px;
}

.gmCheckbox {
  min-width: 20px;
  height: 20px;
  background-color: #ffffff;
  border: 1px solid var(--blue-primary);
  border-radius: 5px;
  margin-right: 10px;
}

.checkboxOn {
  background-color: var(--blue-primary);
}

.error {
  color: var(--danger);
  font-size: 12px;
}

.errorBorder {
  border-color: var(--danger) !important;
}
