.header {
  background-color: var(--blue-dark);
  color: var(--neutral-light);
  font-weight: 400;
  padding: 15px 0px;
  width: 100%;

  position: sticky;
  top: 0;
  left: 0;

  z-index: 5;
}

.navWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: relative;
  z-index: 10;
}

.siteLink {
  text-decoration: none;
  display: flex;
}

.siteInfo {
  display: flex;
  justify-content: space-between;
}

.siteInfo nav {
  margin-left: 60px;
}

.siteIcon {
  width: 30px;
  max-height: 30px;
}

.siteName {
  color: var(--blue-primary);
  font-size: 22px;
  padding-left: 5px;
  font-weight: 400 !important;
  font-family: "DMSerifDisplay";
}

.siteName > .stub {
  color: var(--orange-primary);
  letter-spacing: 1px;
}

.navBar a,
.mobileNav a {
  display: block;
  text-decoration: none;
  align-items: center;
  color: var(--neutral-light);
}

.mobileNav a {
  color: var(--neutral-dark-0);
}

.navBar,
.headBtn {
  display: none;
}

.headBtn {
  gap: 15px;
  margin-right: 15px;
  justify-content: space-evenly;
}

.siteInfo,
.loginBtn,
.signUpBtn {
  cursor: pointer;
}

.options {
  display: flex;
  justify-content: center;
  align-items: center;
}

.options svg {
  width: 30px;
  height: 30px;
}

.mobileNavWrapper {
  height: 100%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 50px;
}

.mobileNavWrapper a {
  padding: 7.5px 0;
}

.mobileNav {
  position: fixed;
  top: 0;
  left: -100%;
  width: 70%;
  height: 100vh;
  display: grid;
  row-gap: 10px;
  align-content: flex-start;
  background: var(--neutral-light);
  transition: all 0.3s ease;
  z-index: 10;
}

.mobileNav > header {
  border-bottom: 1px solid var(--neutral-dark-6);
  padding: 11px 20px;
  padding-top: 12px;
}

.showNavbar {
  left: 0;
}

.actions {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.loginBtn,
.signUpBtn {
  font-family: "Poppins" !important;
  line-height: 40px !important;
  border-radius: 50px;
  min-width: unset !important;
  font-size: 15px !important;
}

.signUpBtn {
  background: var(--blue-primary) !important;
  color: var(--neutral-light) !important;
}

@media screen and (min-width: 768px) {
  .navBar {
    display: flex;
    align-items: center;
  }

  .navLinks {
    display: flex;
    gap: 35px;
    font-size: 14px;
  }

  .navBar a {
    display: inline;
  }

  .headBtn {
    display: flex;
    flex-direction: row;
  }

  .actions {
    display: flex;
    flex-direction: row;
    gap: 5px;
  }

  .loginBtn {
    border: none !important;
    font-weight: bold !important;

    background-color: transparent !important;
  }

  .options {
    display: none;
  }
}

@media screen and (min-width: 1024px) {
  .navBar a {
    font-size: 18px;
  }

  .siteName {
    padding-left: 10px;
  }

  .mobileNav {
    display: none;
  }
}

@media screen and (min-width: 1366px) {
  .siteIcon {
    margin-right: 8px;
  }

  .siteName {
    font-size: 24px;
    line-height: 28px;
    margin-top: 2px;
    padding-left: 0;
  }

  .navBar a {
    font-size: 16px;
  }
}
