.image {
  height: auto;
  width: 100%;

  padding: 10px;

  border-radius: 10px;
  background-color: var(--neutral-dark-6);
}

.image img {
  width: 100%;
  height: 100%;
}
