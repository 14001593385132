html {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  scroll-behavior: smooth;
}
iframe {
  display: none !important;
}
body {
  margin: 0;
}

body * {
  box-sizing: border-box;
}

.pageWrapper {
  width: 100%;
  min-height: 100vh;
  background-color: var(--neutral-light);
}

.contentWrapper {
  padding-bottom: 50px;
  color: var(--neutral-dark-1);
}

.contentWrapper a {
  text-decoration: none;
  color: var(--orange-primary);
}

.bulletPointAlpha {
  list-style: lower-alpha;
}
.modal_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(217, 217, 217, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

@media screen and (min-width: 768px) {
  .contentWrapper {
    padding: 0 100px;
    padding-bottom: 50px;
  }
}

@media screen and (min-width: 1366px) {
  .contentWrapper {
    padding: 0 20%;
    padding-bottom: 50px;
  }
}

.gmContainer {
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
}

@media screen and (min-width: 640px) {
  .gmContainer {
    max-width: 640px;
    margin: 0 auto;
  }
}

@media screen and (min-width: 768px) {
  .gmContainer {
    max-width: 768px;
  }
}

@media screen and (min-width: 1024px) {
  .gmContainer {
    max-width: 1024px;
  }
}

@media screen and (min-width: 1280px) {
  .gmContainer {
    max-width: 1280px;
    padding-left: 150px;
    padding-right: 150px;
  }
}

@media screen and (min-width: 1536px) {
  .gmContainer {
    max-width: 1536px;
    /* padding-left: 250px; */
    /* padding-right: 250px; */
  }
}
