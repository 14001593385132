.container {
  display: flex;
  align-items: center;
  border-radius: 50px;
  background-color: white;
  border: 2px solid var(--blue-primary);
  margin-top: 20px;
  padding: 3px;
  width: 100%;
  align-self: start;
}

.input {
  border: none;
  padding: 5px 20px;
  flex: 1;
  font-size: 16px;
  outline: none;
  border-radius: 50px 0 0 50px;
  background-color: transparent;
}

.input::placeholder {
  color: #dcdcdc;
}

.button {
  background-color: var(--blue-primary);
  color: white;
  padding: 15px 26px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 50px;
  transition: background-color 0.3s;
}

.formGroup,
.iconFormGroup {
  width: 100%;
}

input,
textarea {
  line-height: 36px !important;
  width: 100%;

  border: 1px solid var(--neutral-dark-4);
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 5px;
  outline: none;
  color: var(--neutral-dark-2);
  font-size: 16px;
}

textarea {
  line-height: unset !important;
  padding: 5px 10px;
}

.required {
  color: var(--danger);
}

.date {
  width: 100%;
}

input:focus,
input:hover,
textarea:focus,
textarea:hover {
  border-color: var(--blue-primary);
}

input:invalid,
textarea:invalid {
  border-color: var(--danger);
}

input:disabled textarea:disabled {
  border-color: var(--neutral-dark-5);
}

.iconFormGroup {
  display: flex;
  justify-content: center;
  align-content: center;

  background: transparent;
  border: 1px solid var(--neutral-dark-4);
  border-radius: 5px;
}

.iconFormGroup input {
  border: none;
}

.inputIcon {
  align-self: center;
  height: fit-content;
  margin: 0 5px;
  color: var(--neutral-dark-3);
}

.inputIconLeft {
  padding-left: 0;
}

.iconFormGroup:focus-within,
.iconFormGroup:hover {
  border-color: var(--blue-primary);
}

.emailInputContainer {
}

/**/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: "color 9999s ease-out, background-color 9999s ease-out";
  transition-delay: 9999s;

  -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
  -webkit-transition-delay: 9999s;
}

.checkboxWrapper label {
  font-size: 1rem;
  line-height: 24px;
}

.gmCheckbox {
  width: 20px;
  height: 20px;
  background-color: var(--neutral-light);
  border: 1px solid var(--blue-primary);
  border-radius: 5px;
  cursor: pointer;
}

.checkboxOn {
  background-color: var(--blue-primary);
}

.checkboxWrapper {
  display: flex;
  align-items: center;
}

.checkboxWrapper div {
  margin-bottom: 0.5rem;
  margin-right: 0.5rem;
}

.error {
  color: var(--danger);
  font-size: 12px;
}

.errorBorder {
  border-color: var(--danger) !important;
}

@media screen and (min-width: 768px) {
  .iconFormGroup {
    height: 40px;
  }
}
