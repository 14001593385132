.review {
  padding: 100px 0;
  background-color: var(--blue-transparent-15);
}

.revewContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 50px;

  width: 100%;
}

.revewContainer h2 {
  font-family: "PlayfairDisplay";
  font-size: 24px;
  margin: 0px;
  color: var(--neutral-dark-1);
  text-align: center;
}

.blueText {
  color: var(--blue-primary);
}

.revewContainer h2 .stud {
  color: var(--orange-primary);
  letter-spacing: 2px;
}

.revewList {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
}

.revewList::-webkit-scrollbar {
  display: none;
}

.revewList::-webkit-scrollbar-thumb {
  background-color: #d6d6d6;
  border-radius: 10px;
}

.testimonyCard {
  border: 1px solid var(--blue-transparent);
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 20px;
  background-color: #ffffffcc;

  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
}

.testimonyCard p {
  color: #333;
  font-family: "Poppins";
  font-size: 14px;
  margin: 0;
  white-space: pre-line;
}

.testimonyAuthor {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.testimonyImage {
  width: 50px;
  height: 50px;
  border: 1px solid var(--neutral-dark-3);
  border-radius: 50%;
  margin-right: 15px;
  padding: 2.5px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.authorIcon {
  width: 100%;
  /* height: 100%; */
  border-radius: 50%;
}

.authorInfo h4 {
  font-size: 14px;
  color: #000;
  font-weight: bold;
  margin: 0;
}

.authorInfo h5 {
  font-size: 0.9rem;
  color: #888;
  margin: 0;
}

@media screen and (min-width: 768px) {
  .revewContainer {
    gap: 20px;
  }

  .revewContainer h2 {
    font-size: 36px;
    text-align: left;
    margin-bottom: 50px;
    width: 60%;
  }

  .revewList {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;

    width: 100%;
  }
}

@media screen and (min-width: 1024px) {
  .revewContainer {
    gap: 50px;
  }

  .revewContainer h2 {
    margin-bottom: 0px;
    width: 60%;
  }
}

@media screen and (min-width: 1280px) {
  .revewContainer {
    flex-direction: row;
    justify-content: space-between;
    gap: 50px;
  }

  .revewContainer h2 {
    font-size: 32px;
    margin-bottom: 0px;
    width: 30%;
  }

  .revewList {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;

    width: 70%;
  }
}
