.feature {
  display: flex;
  flex-direction: column-reverse;

  align-items: center;
  border-radius: 12px;
  gap: 50px;

  transition: transform 0.3s ease-in-out;
}

.feature:hover {
  transform: translateY(-5px);
}

.imageContainer {
  width: 100%;
}

.imageContainer img {
  width: 100%;
}

.featureContent {
  flex-grow: 1;
}

.tag {
  display: inline-block;
  border: 1px solid;
  border-radius: 4px;
  color: var(--neutral-dark-3);
  font-size: 0.75rem;
  font-weight: bold;
  margin-bottom: 0px;
  padding: 5px 10px;
  text-transform: uppercase;
}

.title {
  font-size: 24px;
  font-family: "PlayfairDisplay";
  color: var(--neutral-dark-1);
  margin: 15px 0;
  margin-top: 10px;
}

.description {
  color: var(--neutral-dark-1);
  margin-bottom: 20px;
  white-space: pre-line;
}

.actionBtn {
  display: flex;
  align-items: center;
  gap: 20px;

  background-color: var(--blue-primary);
  border-radius: 50px;
  margin-top: 30px;
  padding: 9px 25px;
  width: fit-content;

  color: var(--neutral-light);
  cursor: pointer;
  text-align: center;
}

.actionBtn a {
  color: var(--neutral-light);
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
}

.getStarted {
  font-weight: 500;
  transition: background-color 0.3s;
}

.icon {
  display: inline-flex;
  align-items: center;
}

@media screen and (min-width: 768px) {
  .feature {
    flex-direction: row;
  }

  .rtl {
    flex-direction: row-reverse;
  }

  .title {
    font-size: 24px;
  }

  .description {
    font-size: 16px;
  }
  .imageContainer {
    width: 55%;
  }
  .featureContent {
    justify-content: space-between;
    width: 45%;
  }

  .actionBtn {
    text-align: left;
    width: 180px;
  }
  .actionBtn a {
    color: #fff;
    font-family: "Poppins";
    font-size: 13px;
    line-height: 18px;
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;
  }
}

@media screen and (min-width: 1024px) {
  .title {
    font-size: 24px;
  }

  .description {
    font-size: 16px;
  }

  .actionBtn {
    text-align: left;
    width: 180px;
  }
  .actionBtn a {
    color: #fff;
    font-family: "Poppins";
    font-size: 13px;
    line-height: 18px;
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;
  }
}
