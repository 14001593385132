.spinner {
  display: inline-block;
  width: 24px;
  height: 24px;
}

@keyframes ldioSpin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.ldioSpin div {
  box-sizing: border-box !important
}

.ldioSpin>div {
  position: absolute;
  width: 72px;
  height: 72px;
  top: 14px;
  left: 14px;
  border-radius: 50%;
  border: 8px solid #000;
  border-color: #ffffff transparent #ffffff transparent;
  animation: ldioSpin 0.6666666666666666s linear infinite;
}

.ldioSpin>div:nth-child(2) {
  border-color: transparent
}

.ldioSpin>div:nth-child(2) div {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(45deg);
}

.ldioSpin>div:nth-child(2) div:before, .ldioSpin>div:nth-child(2) div:after {
  content: "";
  display: block;
  position: absolute;
  width: 8px;
  height: 8px;
  top: -8px;
  left: 24px;
  background: #ffffff;
  border-radius: 50%;
  box-shadow: 0 64px 0 0 #ffffff;
}

.ldioSpin>div:nth-child(2) div:after {
  left: -8px;
  top: 24px;
  box-shadow: 64px 0 0 0 #ffffff;
}

.dualRing {
  width: 24px;
  height: 24px;
  display: inline-block;
  overflow: hidden;
  background: none;
}

.ldioSpin {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.24);
  backface-visibility: hidden;
  transform-origin: 0 0;
  /* see note above */
}

.ldioSpin div {
  box-sizing: content-box;
}