.switchWrapper {
  display: inline-block;
}

.switchLabel {
  position: relative;
  display: inline-block;
  width: 46px;
  height: 18px;
  cursor: pointer;
}

.switchInput {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  top: 3px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #f2f9ff;
  transition: 0.4s;
  border-radius: 34px;
  display: flex;
  align-items: center;
  color: #08437e8b;
  font-size: 15px;
  width: 46px;
  height: 20px;
  padding-right: 5px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.checked {
  background-color: var(--blue-primary);
  color: #fff;
}

.checked:before {
  transform: translateX(26px);
}

.disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
