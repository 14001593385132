.wrapper {
  padding: 25px;
}

.accordionWrapper > summary {
  cursor: pointer;

  margin-bottom: 5px;
  padding: 7.5px 0;

  list-style: none;

  color: var(--neutral-light);
  font-size: 14px;
}

summary.childAccordion {
  padding-left: 15px;
}

.active {
  background-color: var(--blue-transparent-15);
  font-weight: bold;
}

.accordionLeafItem {
  padding: 7.5px 15px;
  font-size: 14px;
}

.accordionLeafItem a,
.accordionLeafItem a:active {
  color: var(--neutral-light);
  text-decoration: none;
}

.accordionLeafItem a:hover {
  text-decoration: underline;
}

.childrenWrapper {
  padding-left: 15px;
}
