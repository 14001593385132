.slick-prev:before,
.slick-next:before {
    color: #888888;
    font-size: 28px;
}

.slick-prev {
    left: 5px;
    z-index: 1;
}

.slick-next {
    right: 5px;
}

.swiper-wrapper {
    padding: 50px 0;
}

.swiper-slide-active>div {
    filter: none !important;
    padding-bottom: 15%;
}

@media screen and (min-width:1024px) {
    .swiper-slide-active>div {
        filter: blur(2px);
        padding-bottom: 15%;
    }

    .swiper,
    .swiper-container {
        margin-left: -15px;
        margin-right: -15px;
    }

    .swiper-slide-next>div {
        filter: none !important;
        padding-bottom: 18%;
    }
}