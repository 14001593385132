.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  background: var(--blue-dark);
}

.layout {
  height: 85vh;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 75px;
}

.mainWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;

  height: 100%;
}

.main h1 {
  display: block;
  margin: 0;
}
.mainAmpVoice {
  width: 300px;
  max-height: 490px;
}

.emailInputContainer {
  width: 100%;
  align-self: center;
}

.mainText {
  display: grid;
  row-gap: 20px;
  max-width: 600px;
  color: var(--neutral-light);
}

.mainText h1 {
  font-family: "PlayfairDisplay";
  font-size: 40px;
  font-weight: 500;
}

.blue {
  color: var(--blue-primary);
}

.lightBlue {
  color: var(--blue-light);
}

.orange {
  color: var(--orange-primary);
}

.tagline {
  font-family: "Poppins";
  font-size: 16px;
  font-weight: 400;
}

.mainCTA {
  width: 100%;
  font-family: "Poppins";
  font-weight: 600;
  max-width: 220px;
  padding: 15px 0;
  text-align: center;
  border-radius: 5px;
  font-size: 18px;
  line-height: 24px;
  cursor: pointer;
}

.landingImage {
  width: 100%;
  display: none;
}

.landingImage img {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .layout {
    height: auto;

    padding-bottom: 150px;
    padding-top: 150px;
  }

  .mainText {
    max-width: unset;
    gap: 30px;
  }

  .main h1 {
    font-size: 48px;
  }

  .tagline {
    max-width: 650px;
    margin-left: auto;
    margin-right: auto;

    font-size: 20px;
  }

  .emailInputContainer {
    width: 80%;
  }

  .mainText {
    text-align: center;
  }

  .landingImage {
    /* display: inline-block; */
    align-self: center;
    width: 600px;
  }
}

@media screen and (min-width: 1024px) {
  .landingImage {
    /* display: inline-block; */
    align-self: center;
    width: 800px;
  }
}

@media screen and (min-width: 1280px) {
  .layout {
    /* flex-direction: row; */
    gap: 75px;

    padding-bottom: 175px;
    padding-top: 175px;
  }

  .tagline {
    /* max-width: unset; */
    font-size: 20px;
    max-width: 800px;
  }

  .emailInputContainer {
    width: 70%;
  }
}

@media screen and (min-width: 1378px) {
  .layout {
    height: 82.5vh;
  }
}
