.inline,
.main {
  border-radius: 5px;
  padding: 15px;

  background-color: var(--neutral-dark-6);
  color: var(--blue-dark);
  font-family: monospace;
  font-size: 12px;
}

.inline {
  padding: 2px 5px;
}

.main {
  white-space: pre;
}
