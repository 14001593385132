.wrapper {
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100vh;
  margin: 0 auto;

  overflow: hidden;
}

.layout {
  position: relative;
  display: flex;
  height: 100%;
}

.navWrapper {
  height: 100%;
  width: 30%;

  background-color: var(--blue-dark);

  overflow-y: auto;
}

.contentWrapper {
  width: 70%;
  height: 90%;
  padding: 25px 50px;
  overflow-y: auto;
}

@media screen and (min-width: 1280px) {
  .navWrapper {
    max-width: 22.5%;
  }

  .contentWrapper {
    width: 77.5%;
  }

  .content {
    width: 800px;
  }
}

@media screen and (min-width: 1500px) {
  .navWrapper {
    max-width: 15%;
  }

  .contentWrapper {
    width: 85%;
    padding-left: 15%;
  }
}
