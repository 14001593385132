.wrapper {
  /* background: linear-gradient(289.53deg, rgba(0, 59, 113, 0.9) 0%, rgba(42, 153, 255, 0.9) 100%); */
  border-radius: 10px;
  margin: 75px 0;
}

.pricingWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}

.pricingInfo {
  text-align: left;
  flex-direction: column;
  align-items: self-start;
}

.heading {
  font-family: "PlayfairDisplay", serif;
  font-weight: 500;
  font-size: 24px;
  color: var(--neutral-dark-1);
}

.subheading {
  color: var(--neutral-dark-1);
  font-size: 14px;
  margin: 20px 0 50px 0;
}

.getStartedBtn {
  border: none;
  border-radius: 50px;
  padding: 0px 35px;
  font-family: "poppins";
  font-size: 1.1rem;
  margin-top: 20px;
  cursor: pointer;
}

.plansSection {
  display: flex;
  flex-direction: column;
  gap: 20px;

  width: 100%;
}

.currencyWrapper {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  width: 100%;
}

.moduleSelector {
  width: 60%;
}

.currency {
  width: 40%;
}

.plansWrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.planWrapper {
  background-color: var(--orange-transparent-25);

  border: 1px solid var(--orange-primary);
  border-radius: 8px;
  padding: 20px;
  width: 100%;

  font-weight: unset;
}

.subscription {
  background-color: var(--blue-transparent);
  border: 1px solid var(--blue-primary);
}

.planTitle {
  color: var(--neutral-dark-2);
  font-family: "DMSerifDisplay";
  letter-spacing: 1px;

  margin: 0;
  margin-bottom: 5px;
}

.planPrice {
  color: var(--neutral-dark-3);
  font-size: 14px;

  margin-bottom: 10px;
}

.planPrice > span {
  font-size: 28px;
  font-weight: bold;
  color: var(--orange-primary);
}

.subscription .planPrice > span {
  color: var(--blue-primary);
}

.switchWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.switchWrapper > span {
  color: var(--neutral-dark-2);
  font-size: 12px;
}

.benefits {
  display: flex;
  flex-direction: column;
  gap: 2px;

  border-top: 1px solid var(--orange-transparent-50);
  padding-top: 20px;
}

.subscription .benefits {
  border-top: 1px solid var(--blue-transparent-75);
}

.benefit {
  display: flex;
  align-items: center;
  gap: 10px;

  color: var(--neutral-dark-2);
  font-size: 12px;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;

  color: var(--orange-primary);
}

.subscription .icon {
  color: var(--blue-primary);
}

.btn {
  border: none;

  background-color: var(--orange-primary);
  color: var(--neutral-light);
}

.subscription .btn {
  background-color: var(--blue-primary);
}

@media screen and (min-width: 768px) {
  .pricingInfo {
    text-align: left;
    max-width: 70%;
  }

  .heading {
    font-size: 32px;
  }

  .subheading {
    font-size: 16px;
    margin-bottom: 20px;
  }

  .pricingWrapper {
    align-items: unset;
  }

  .plansWrapper {
    flex-direction: row;
  }

  .moduleSelector {
    width: 28.25%;
  }

  .currency {
    width: 150px;
  }
}

@media screen and (min-width: 1024px) {
  .pricingWrapper {
    flex-direction: row;
    justify-content: space-between;
  }

  .pricingInfo {
    display: flex;
    width: 40%;
  }

  .heading {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1440px) {
  .pricingInfo {
    display: flex;
    width: 50%;
  }
}
