.button {
  display: flex;
  align-items: center;
  gap: 10px;

  font-family: "Poppins";
  line-height: 40px;
  min-width: 100px;
  padding: 0 25px;

  border: 1px solid var(--blue-primary);
  border-radius: 50px;

  background-color: var(--neutral-light);
  color: var(--blue-primary);
  font-weight: 500;
  font-size: 15px;
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cta {
  background-color: var(--blue-primary);
  border-color: var(--blue-primary);
  color: var(--neutral-light);
  font-family: "Poppins";
  font-weight: 400;
  font-size: 32px;
}

@media screen and (min-width: 768px) {
  .cta {
    padding: 10px 25px;
  }
}
